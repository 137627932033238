export const useApi = async (request, opts) => {
    const config = useRuntimeConfig()
    const {$bus} = useNuxtApp()

    return $fetch(
        request,
        {
            baseURL: `${config.public.api_url}/api/`,

            onResponse({request, response, options}) {
                const status = response.status

                if ((status >= 200 && status < 300) || status === 422) {
                    return response
                }

                $bus.$emit('flash', {
                    type: 'error',
                    title: response._data.message,
                    timeout: 5000,
                })

                return Promise.reject(response)
            },
            ...opts,
        }
    )
}